import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Carousel from 'react-bootstrap/Carousel'

const MconstructionsPage = () => (
  <Layout activeItem="Металлоконструкции">
    <Seo title="Металлоконструкции"/>
    <h1>Металлоконструкции</h1>
    <Carousel pause="false" interval="3000">
      <Carousel.Item>
        <img src="/images/mconstructions/mconstruction1.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/mconstructions/mconstruction2.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/mconstructions/mconstruction3.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/mconstructions/mconstruction4.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/mconstructions/mconstruction5.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/mconstructions/mconstruction6.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/mconstructions/mconstruction7.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/mconstructions/mconstruction9.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/mconstructions/mconstruction10.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
    </Carousel>
                </Layout>
)

export default MconstructionsPage
